
    .top{
        width: 100%;
        height: 3em;
        position: sticky;
        margin-top: 0;
        display: flex;
        align-items: center;
        font-family: 'Quicksand', sans-serif;
        z-index: 999;
        background-color: #fffbee;
    }
    
    .topLeft{
        display: none;
    } 
    
    .topRight{
        display: flex;
        align-items: flex-end;
        justify-content: center;
        margin: auto;
    }
    
    .topList{
        display: flex;
        justify-content: center;
        margin: 0;
        padding: 0;
        list-style: none;
    }
    
    .topListItem{
        margin-left:0.5em;
        margin-right: 0.5em;
        font-size: 24px;
        font-weight: 300;
        cursor: pointer;
        color: #42725f;
        text-decoration: none;
    }
    
    .resumeLink{
        color: #42725f;
        text-decoration: none;
    }

    .topIcon{
        margin-right: 24px;
        cursor: pointer;
    }

@media (min-width: 481px){ /* breakpoint for tablets */
    .top{
        width: 100%;
        height: 3em;
        position: sticky;
        margin-top: 0;
        display: flex;
        align-items: center;
        font-family: 'Quicksand', sans-serif;
        z-index: 999;
        background-color: #fffbee;
    }
    
    .topLeft{
        display: none;
    } 
    
    .topRight{
        display: flex;
        align-items: flex-end;
        justify-content: center;
        margin: auto;
    }
    
    .topList{
        display: flex;
        justify-content: center;
        margin: 0;
        padding: 0;
        list-style: none;
    }
    
    .topListItem{
        margin-left:0.5em;
        margin-right: 0.5em;
        font-size: 24px;
        font-weight: 300;
        cursor: pointer;
        color: #42725f;
        text-decoration: none;
    }
    
    .resumeLink{
        color: #42725f;
        text-decoration: none;
    }
}

@media (min-width: 769px){ /* breakpoint for small screens */
    .top{
        width: 100%;
        height: 3em;
        position: sticky;
        margin-top: 0;
        display: flex;
        align-items: center;
        font-family: 'Quicksand', sans-serif;
        z-index: 999;
        background-color: #fffbee;
    }
    
    .topLeft{
        flex:1;
        display: flex;
        align-items: flex-start;
        justify-content: flex-start;
        margin-left: 2em;
    } 
    
    .topRight{
        flex: 1;
        display: flex;
        margin-right: 2em;
        align-items: flex-end;
        justify-content: flex-end;
    }
    
    .topIcon{
        font-size: 40px;
        margin-right: 24px;
        color: #42725f;
        cursor: pointer;
    }
    
    .topList{
        display: flex;
        justify-content: center;
        margin: 0;
        padding: 0;
        list-style: none;
    }
    
    .topListItem{
        margin-right: 30px;
        font-size: 24px;
        font-weight: 300;
        cursor: pointer;
        color: #42725f;
        text-decoration: none;
    }
    
    .resumeLink{
        color: #42725f;
        text-decoration: none;
    }
}

@media (min-width: 1025px){ /* breakpoint for large screens */

    .top{
        width: 100%;
        height: 50px;
        position: sticky;
        margin-top: 0;
        display: flex;
        align-items: center;
        font-family: 'Quicksand', sans-serif;
        z-index: 999;
        background-color: #fffbee;
    }
    
    .topLeft{
        flex:1;
        display: flex;
        align-items: flex-start;
        justify-content: flex-start;
        margin-left: 100px;
    } 
    
    .topRight{
        flex: 1;
        display: flex;
        margin-right: 100px;
        align-items: flex-end;
        justify-content: flex-end;
    }
    
    .topIcon{
        font-size: 40px;
        margin-right: 24px;
        color: #42725f;
        cursor: pointer;
    }
    
    .topList{
        display: flex;
        justify-content: center;
        margin: 0;
        padding: 0;
        list-style: none;
    }
    
    .topListItem{
        margin-right: 30px;
        font-size: 24px;
        font-weight: 300;
        cursor: pointer;
        color: #42725f;
        text-decoration: none;
    }
    
    .resumeLink{
        color: #42725f;
        text-decoration: none;
    }
}